<template>
  <div>
    <vs-popup class="popup90" :fullscreen="false" title="Editar Movimentação" :active.sync="localShow">

      <dialog-sig style="z-index: 9999999999;" :show="popupServiceQty" title="QUANTIDADE" icon="warning"
        @action="changeServiceQty"
        @cancel="closeChangeServiceQty"
        actionButtonText="Continuar" actionButtonColor="warning">
          <div class="vx-row">
            <div class="vx-col w-full mt-0">
              <vs-input type="number" min="1" class="w-full" label="Quantidade" v-model="serviceQty" />
            </div>
          </div>
      </dialog-sig>

      <permission-password style="z-index: 9999999999;" :show="popupPermissionPassword" title="ALERTA DE BAIXA RETROATIVA" icon="warning"
        actionButtonText="Prosseguir"
        @action="save(true)"
        @cancel="popupPermissionPassword = false">
      </permission-password>

      <div class="vx-row">
        <!-- Pacotes agora chamamos de serviços e serviços do pacote são ítens -->
        <div class="vx-col md:w-1/6 w-full mt-0">
          <label class="vs-input--label">Serviços</label>
          <v-select v-model="transaction.servicePackages" @input="recalculateTotal()" @option:deselected="serviceDeselected" @option:selected="newServiceSelected"
            data-vv-as="Serviços" data-vv-scope="transaction" v-validate="{ required: transaction.type === 'CREDIT' }" name="services"
            :options="servicePackages" multiple placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Nenhum resultado encontrado.
            </template>
          </v-select>
          <span class="text-danger text-sm" v-show="errors.has('transaction.services')">{{ errors.first('transaction.services') }}</span>
        </div>
        <div class="vx-col md:w-1/6 w-full mt-0">
          <vs-input class="w-full" label="Valor" ref="subTotal" v-model="transaction.value_parcel" v-currency="currencyConfig" />
        </div>
        <div class="vx-col md:w-1/6 w-full mt-0">
          <label class="vs-input--label">Desconto {{ transaction.discount_type }}</label>
          <vx-input-group prependClasses="border border-solid border-grey border-r-0">
            <template slot="append">
              <div class="append-text btn-addon">
                <vs-button @click="toggleDiscountType">{{ transaction.discount_type }}</vs-button>
              </div>
            </template>
            <vs-input key="1__" v-if="transaction.discount_type === '%'" class="w-full" type="number" v-model="transaction.discount" />
            <vs-input key="2___" v-else class="w-full" ref="discount" v-model="transaction.discount" v-currency="currencyConfig" />
          </vx-input-group>
        </div>
        <div class="vx-col md:w-1/6 w-full mt-0">
          <label class="vs-input--label">Acréscimo {{ transaction.addition_type }}</label>
          <vx-input-group prependClasses="border border-solid border-grey border-r-0">
            <template slot="append">
              <div class="append-text btn-addon">
                <vs-button @click="toggleAdditionType">{{ transaction.addition_type }}</vs-button>
              </div>
            </template>
            <vs-input key="3__" v-if="transaction.addition_type === '%'" class="w-full" type="number" v-model="transaction.addition" />
            <vs-input key="4___" v-else class="w-full" ref="addition" v-model="transaction.addition" v-currency="currencyConfig" />
          </vx-input-group>
        </div>
        <div class="vx-col md:w-1/6 w-full mt-0">
          <vs-input type="date" class="w-full" label="Vencimento" v-model="transaction.expiration_date" @change="recalculateTotal()"
            data-vv-as="Vencimento" data-vv-scope="transaction" v-validate="'required'" name="expiration_date" />
          <span class="text-danger text-sm" v-show="errors.has('transaction.expiration_date')">Este campo é obrigatório.</span>
          <span class="text-danger text-sm" v-if="!isSameOrAfterToday(transaction.expiration_date)">Movimentação vencida.</span>
        </div>
        <div class="vx-col md:w-1/6 w-full mt-0">
          <label class="vs-input--label">Novo Valor</label>
          <h1 class="flex flex-wrap justify-center">
            <sup class="text-lg">R$</sup>
            <span>{{ total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }).replace('R$', '') }}</span>
          </h1>
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col md:w-1/6 w-full mt-2">
          <label class="vs-input--label">Tipo de Pagamento</label>
          <v-select class="vue_select_drop_size_180" @input="filterAccounts" v-model="transaction.type_payment_id" :reduce="option => option.value" :clearable="false"
            data-vv-as="Tipo de Pagamento" data-vv-scope="transaction" v-validate="'required'" name="type_payment_id"
            :options="typePayments" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Desculpe, nenhum resultado encontrado.
            </template>
          </v-select>
          <span class="text-danger text-sm" v-show="errors.has('transaction.type_payment_id')">Este campo é obrigatório.</span>
        </div>
        <div class="vx-col md:w-1/6 w-full mt-2">
          <label class="vs-input--label">Conta</label>
          <v-select class="vue_select_drop_size_180" :disabled="!transaction.type_payment_id" v-model="transaction.account_id" :reduce="option => option.value" :clearable="false"
            data-vv-as="Conta" data-vv-scope="transaction" v-validate="'required'" name="account"
            :options="accountsFilteredOptions" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Desculpe, nenhum resultado encontrado.
            </template>
          </v-select>
          <span class="text-danger text-sm" v-show="errors.has('transaction.account')">Este campo é obrigatório.</span>
        </div>
        <div class="vx-col md:w-1/6 w-full mt-2">
          <label class="vs-input--label">Plano de contas</label>
          <v-select class="vue_select_drop_size_180" v-model="transaction.account_plan_id" :reduce="option => option.value"
            :options="transaction.type === 'DEBIT' ? accountPlansDespesa : accountPlans" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Desculpe, nenhum resultado encontrado.
            </template>
          </v-select>
        </div>
        <div class="vx-col md:w-1/6 w-full mt-2">
          <div class="flex flex-wrap items-center justify-end">
          <label class="vs-input--label mr-2">Aplicar Juros</label>
            <vs-switch class="w-1/2 mt-2" icon-pack="feather" @change="recalculateTotal()" vs-icon-on="icon-check-circle" vs-icon-off="icon-slash" v-model="transaction.applyInterest">
              <span slot="on">SIM</span> <span slot="off">NÃO</span>
            </vs-switch>
          </div>

          <div class="flex flex-wrap items-center justify-end">
            <label class="vs-input--label mr-2">Situação</label>
            <vs-switch class="w-1/2 mt-2" icon-pack="feather"
              @input="changeSituation"
              vs-icon-on="icon-check-circle" vs-icon-off="icon-slash" v-model="transaction.situation">
              <span slot="on">QUITADO</span> <span slot="off">ABERTO</span>
            </vs-switch>
          </div>
        </div>
        <div class="vx-col md:w-1/6 w-full mt-2">
          <vs-input type="date" class="w-full" label="Data Pagamento" v-model="transaction.payday" @change="recalculateTotal(); checkPayday(transaction)" :disabled="!transaction.situation"
            data-vv-as="Vencimento" data-vv-scope="transaction" v-validate.initial="{ required: transaction.situation }" name="payday" />
          <span class="text-danger text-sm" v-show="errors.has('transaction.payday')">Campo obrigatório.</span>
          <span class="text-danger text-sm" v-show="transaction.futureDate">Data futura não permitida</span>
        </div>
        <div class="vx-col md:w-1/6 w-full mt-2">
          <vs-input class="w-full" label="Valor Pago" ref="valuePay" v-model="transaction.value_pay" :disabled="!transaction.situation" v-currency="currencyConfig" />
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col w-full mt-2">
          <vs-input class="w-full" label="Descrição" v-model="transaction.observation" />
        </div>
      </div>

      <vs-divider />

      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mt-4 flex flex-wrap items-center justify-end">
            <vs-button color="secondary" class="ml-auto" @click="cancel">Cancelar</vs-button>
              <vs-button class="ml-4" @click="save()" :disabled="!validateTransaction || disableTransactionSave || disableSave">Salvar</vs-button>
          </div>
        </div>
      </div>

    </vs-popup>

  </div>
</template>

<script>
// Store Module
import moduleTypePayment from '@/store/type-payment/moduleTypePayment.js'
import moduleAccount from '@/store/account/moduleAccount.js'
import moduleServicePackage from '@/store/service-package/moduleServicePackage.js'
import moduleAccountPlan from '@/store/account-plan/moduleAccountPlan.js'
import moduleTransaction from '@/store/transaction/moduleTransaction.js'

import moment from 'moment'
import _ from 'lodash'
import { setValue, getValue, CurrencyDirective, CurrencyInput } from 'vue-currency-input'
const vSelect = () => import(/* webpackChunkName: "vSelect" */ 'vue-select')
const permissionPassword = () => import(/* webpackChunkName: "permissionPassword" */ '@/components/permission-password/permissionPassword.vue')
const dialogSig = () => import(/* webpackChunkName: "dialogSig" */ '@/components/dialog/dialogSig.vue')

export default {
  components: {
    dialogSig,
    vSelect,
    CurrencyInput,
    permissionPassword
  },
  props: {
    show: {
      type: Boolean, default: false
    },
    transactionProp: {
      type: Object
    }
  },
  computed: {
    today () {
      const [DD, MM, YYYY] = new Date().toLocaleDateString('pt-br').split('/')
      return `${YYYY}-${MM}-${DD}`
    },
    user () {
      return this.$store.state.AppActiveUser
    },
    validateTransaction () {
      return !this.errors.any('transaction')
    },
    discount () {
      return this.transaction.discount
    },
    addition () {
      return this.transaction.addition
    },
    subTotal () {
      return this.transaction.value_parcel
    },
    typePayments () {
      return this.$store.getters['typePayment/forSelect']
    },
    accountPlans () {
      return this.$store.getters['accountPlan/forSelectFilterReceipt']
    },
    accountPlansDespesa () {
      return this.$store.getters['accountPlan/forSelectFilterDespesa']
    },
    accounts () {
      return this.$store.getters['account/forSelect']
    }
  },

  watch: {
    show (val) {
      this.localShow = val
    },
    localShow (val) {
      if (!val) {
        this.$emit('cancel')
      } else {
        this.$validator.pause()
        //COMO OS CAMPOS APARECEM E SOMEM DINAMICAMENTE, PRECISO DO BLOCO ABAIXO PARA EVITAR ERROS DE VALIDAÇÃO
        this.$nextTick(() => {
          this.$validator.resume()
          this.$validator.errors.clear()
          this.$validator.validateScopes()
        })

        this.transaction = this.transactionProp
        this.$store.dispatch('typePayment/fetchAll').then(() => { }).catch(err => { console.error(err) })
        this.$store.dispatch('account/fetchAll').then(() => {
          const account_id = this.transaction.account_id
          this.accountsFilteredOptions = this.accounts.filter((account) => {
            return account.type_payments.some(e => e.id === this.transaction.type_payment_id)
          })
          this.transaction.account_id = null
          this.transaction.account_id = account_id
        }).catch(err => { console.error(err) })
        this.$store.dispatch('servicePackage/fetchAll').then(() => { }).catch(err => { console.error(err) })
        this.$store.dispatch('accountPlan/fetchAll').then(() => { }).catch(err => { console.error(err) })
        if (this.transaction.servicePackages) {
          const sp = this.transaction.servicePackages.map(tsp => {
            const idx = this.servicePackages.findIndex(sp => (sp && sp.value && tsp && tsp.id) && sp.value === tsp.id)
            if (idx >= 0) {
              this.servicePackages[idx].label = tsp.type_service && tsp.type_service.toUpperCase() === 'AULA AVULSA' ? `${tsp.name} ${(tsp.category_cnh || '')} x ${tsp.quantity}` : `${tsp.name} ${(tsp.category_cnh || '')}`
              this.servicePackages[idx].data.quantity = tsp.quantity
              // this.$set(this.servicePackages, idx, this.servicePackages[idx])
              return this.servicePackages[idx]
            }
          })

          // TIVEMOS PROBLEMA EM UM CASO ONDE O SERVIÇO HAVIA SIDO EXCLUÍDO
          if (sp[0]) {
            this.transaction.servicePackages = sp
          } else {
            this.transaction.servicePackages = []
          }

        } else {
          this.transaction.servicePackages = []
        }
        setValue(this.$refs.subTotal, this.transaction.value_parcel.toString())
        setValue(this.$refs.valuePay, (0).toString())
      }
    },
    discount () {
      this.recalculateTotal()
    },
    addition () {
      this.recalculateTotal()
    },
    subTotal () {
      this.recalculateTotal()
    }
  },
  data () {
    return {
      localShow: false,
      disableTransactionSave: false,
      disableSave: false,
      popupPermissionPassword: false,
      total: 0,
      transaction: {},
      currencyConfig:  {
        currency: {prefix: 'R$ '},
        valueAsInteger: false,
        distractionFree: false,
        precision: 2,
        autoDecimalMode: true,
        valueRange: { min: 0 },
        allowNegative: false
      },
      accountsFilteredOptions: [],
      servicePackages: [],
      popupServiceQty: false,
      serviceQty: 1

    }
  },

  directives: { currency: CurrencyDirective },

  methods: {


    //EVITA BAIXA COM DATA FUTURA
    checkPayday (parcel) {
      if (moment(parcel.payday).year() > 1000) {
        const difDays = moment(parcel.payday).diff(moment(this.today), 'days')
        if (difDays > 0) {
          parcel.futureDate = true
          this.disableSave = true
        } else {
          parcel.futureDate = false
          this.disableSave = false
        }
      } else {
        parcel.futureDate = false
        this.disableSave = false
      }
    },

    cancel () {
      this.$emit('cancel')
    },
    changeSituation () {
      if (this.transaction.situation) {

        // SE NÃO FOR ADMIN E NÃO TIVER PERMISSÃO - CONTROLE DE ACESSO Edita mas não pode baixar
        const mod = this.user.permissions_user.find(a => a.module === 'spa_student_parcels') // baixar parcelas
        if (!this.user.roles.some(role => role.name === 'admin') && (!mod || !mod.pivot.permissions.includes('save'))) {
          this.$vs.notify({
            time: 5000,
            title: 'Aviso',
            text: 'Você não tem permissão para EXECUTAR ESTA ação.',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning',
            position: 'center-top'
          })
          this.transaction.situation = false
          return
        }

        this.transaction.payday = this.today
      } else {
        this.transaction.payday = ''
        this.transaction.futureDate = false
      }
      this.recalculateTotal()
      this.checkPayday(this.transaction)
    },
    toggleDiscountType () {
      this.transaction.discount = 0
      this.transaction.discount_type = this.transaction.discount_type === '%' ? '$' : '%'
    },
    toggleAdditionType () {
      this.transaction.addition = 0
      this.transaction.addition_type = this.transaction.addition_type === '%' ? '$' : '%'
    },
    isSameOrAfterToday (e) {
      if (moment(e).isValid()) return moment(e).isSameOrAfter(moment().format('YYYY-MM-DD'))
      return true
    },
    newServiceSelected (val) {
      //LAST IN ARRAY IS THE SELECTED
      const lastServiceSelected = val[this.transaction.servicePackages.length - 1]
      if (lastServiceSelected.data.type_service && lastServiceSelected.data.type_service.toUpperCase() === 'AULA AVULSA') {
        this.serviceQty = 1
        lastServiceSelected.data.quantity = this.serviceQty
        this.popupServiceQty = true
      }
    },

    serviceDeselected (val) {
      if (val.data.type_service && val.data.type_service.toUpperCase() === 'AULA AVULSA') {
        this.serviceQty = 1
        val.label = `${val.data.name} x ${this.serviceQty}`
        val.data.quantity = this.serviceQty
      }
    },

    changeServiceQty () {
      const lastServiceSelected = this.transaction.servicePackages[this.transaction.servicePackages.length - 1]
      lastServiceSelected.data.quantity = this.serviceQty
      lastServiceSelected.label = `${lastServiceSelected.data.name} x ${this.serviceQty}`
      // this.changeCreditSubTotal()
      this.popupServiceQty = false
    },

    closeChangeServiceQty () {
      this.serviceQty = 1
      this.popupServiceQty = false
    },

    recalculateTotal () {
      let discount = this.transaction.discount_type === '%' ? this.transaction.discount : (this.$refs.discount ? getValue(this.$refs.discount) : 0)
      let addition = this.transaction.addition_type === '%' ? this.transaction.addition : (this.$refs.addition ? getValue(this.$refs.addition) : 0)

      const subTotal = getValue(this.$refs.subTotal)
      if (this.transaction.discount_type === '%') {
        discount = ((discount / 100) * subTotal).toFixed(2)
      }
      if (this.transaction.addition_type === '%') {
        addition = ((addition / 100) * subTotal).toFixed(2)
      }

      this.total = subTotal - discount + parseFloat(addition)

      if (this.transaction.applyInterest && this.transaction.situation) {
        this.calculateFeesInterest()
      } else if (!this.transaction.situation) {
        this.transaction.payday = ''
        setValue(this.$refs.valuePay, 0)

      } else {
        setValue(this.$refs.valuePay, this.total)
      }
    },
    calculateFeesInterest () {
      if (moment(this.transaction.expiration_date).isBefore(this.transaction.payday) && this.transaction.situation) {
        setValue(this.$refs.valuePay, this.round((this.transaction.value_updated)))
      } else {
        //caso marcado para calcular juros mas pago antes do vencimento
        setValue(this.$refs.valuePay, this.round(this.total))
      }
    },
    round (v) {
      return parseFloat(parseFloat(v).toFixed(2))
    },

    // FILTRA AS CONTAS CONFORME O TIPO DE PAGAMENTO
    filterAccounts (selectFirst = true) {
      this.transaction.account_id = null
      const myArrayFiltered = this.accounts.filter((account) => {
        return account.type_payments.some(e => e.id === this.transaction.type_payment_id)
      })
      this.accountsFilteredOptions = myArrayFiltered
      if (selectFirst && myArrayFiltered.length) {
        this.transaction.account_id = myArrayFiltered[0].value
      }
    },
    save (pass = false) {
      this.disableTransactionSave = true
      this.debounceUpdate(pass)
    },
    update (pass = false) {
      /**
       * CHECAR PAGAMENTO RETROATIVO
       */
      this.disableTransactionSave = false
      if (!pass) {
        if (moment(this.transaction.payday).diff(moment(this.today), 'days') < 0) {
          this.popupPermissionPassword = true
          this.$vs.notify({
            time: 6000,
            title: 'AVISO',
            text: 'Parcela com baixa retroativa',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
          return
        }
      }

      // Loading
      this.$vs.loading()
      this.disableTransactionSave = true

      // É PRECISO TRATAR PARA RECEBER CORRETAMENTE O CAMPO servicePackages
      const transaction =  JSON.parse(JSON.stringify(this.transaction))
      transaction.servicePackages = transaction.servicePackages.map(item => {
        item.data.services.map((s) => { delete s.pivot })
        return item.data
      })
      if (transaction.discount_type === '$') transaction.discount = getValue(this.$refs.discount)
      if (transaction.addition_type === '$') transaction.addition = getValue(this.$refs.addition)
      transaction.value_parcel = this.total
      transaction.value_pay = getValue(this.$refs.valuePay)
      transaction.value_parcel = this.total

      this.$store.dispatch('transaction/update', transaction)
        .then(() => {
          this.$emit('saved')
          this.localShow = false
          this.disableTransactionSave = false

          this.$vs.loading.close()
          this.$vs.notify({
            time: 5000,
            title: 'SUCESSO',
            text: 'Dados Salvos.',
            color: 'success',
            iconPack: 'feather',
            icon: 'icon-check'
          })
        })
        .catch(error => {
          this.$vs.loading.close()
          this.disableTransactionSave = false
          if (error.response.status === 412) {
            this.$vs.notify({
              time: 6000,
              title: 'AVISO',
              text: error.response.data.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'warning'
            })
          } else {
            this.$vs.notify({
              time: 5000,
              title: 'Erro',
              text: error.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger'
            })
          }
        })
    }
  },
  mounted () {
    if (!this.$store.getters['servicePackage/forSelect'].length) {
      this.$store.dispatch('servicePackage/fetchAll').then(() => {
        this.servicePackages = JSON.parse(JSON.stringify(this.$store.getters['servicePackage/forSelect']))
      }).catch(err => { console.error(err) })
    } else {
      this.servicePackages = JSON.parse(JSON.stringify(this.$store.getters['servicePackage/forSelect']))
    }
  },
  created () {
    if (!moduleTypePayment.isRegistered) {
      this.$store.registerModule('typePayment', moduleTypePayment)
      moduleTypePayment.isRegistered = true
    }
    if (!moduleAccount.isRegistered) {
      // this.$store.registerModule('account', moduleAccount)
      moduleAccount.isRegistered = true
    }
    if (!moduleServicePackage.isRegistered) {
      // this.$store.registerModule('servicePackage', moduleServicePackage)
      moduleServicePackage.isRegistered = true
    }
    if (!moduleAccountPlan.isRegistered) {
      // this.$store.registerModule('accountPlan', moduleAccountPlan)
      moduleAccountPlan.isRegistered = true
    }
    if (!moduleTransaction.isRegistered) {
      this.$store.registerModule('transaction', moduleTransaction)
      moduleTransaction.isRegistered = true
    }
    // save
    this.debounceUpdate = _.debounce(this.update, 600)

  }
}
</script>

<style lang="scss">
.popup50 .vs-popup {
  width: 50% !important;
}
.popup80 .vs-popup {
  width: 80% !important;
}
.popup90 .vs-popup {
  width: 90% !important;
}
.noOverflow .vs-popup--content {
  overflow: hidden;
}
.vue_select_drop_size_180 .vs__dropdown-menu {
  max-height: 180px;
}
</style>
