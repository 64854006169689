var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "vs-popup",
        {
          staticClass: "popup90",
          attrs: {
            fullscreen: false,
            title: "Editar Movimentação",
            active: _vm.localShow,
          },
          on: {
            "update:active": function ($event) {
              _vm.localShow = $event
            },
          },
        },
        [
          _c(
            "dialog-sig",
            {
              staticStyle: { "z-index": "9999999999" },
              attrs: {
                show: _vm.popupServiceQty,
                title: "QUANTIDADE",
                icon: "warning",
                actionButtonText: "Continuar",
                actionButtonColor: "warning",
              },
              on: {
                action: _vm.changeServiceQty,
                cancel: _vm.closeChangeServiceQty,
              },
            },
            [
              _c("div", { staticClass: "vx-row" }, [
                _c(
                  "div",
                  { staticClass: "vx-col w-full mt-0" },
                  [
                    _c("vs-input", {
                      staticClass: "w-full",
                      attrs: { type: "number", min: "1", label: "Quantidade" },
                      model: {
                        value: _vm.serviceQty,
                        callback: function ($$v) {
                          _vm.serviceQty = $$v
                        },
                        expression: "serviceQty",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]
          ),
          _c("permission-password", {
            staticStyle: { "z-index": "9999999999" },
            attrs: {
              show: _vm.popupPermissionPassword,
              title: "ALERTA DE BAIXA RETROATIVA",
              icon: "warning",
              actionButtonText: "Prosseguir",
            },
            on: {
              action: function ($event) {
                return _vm.save(true)
              },
              cancel: function ($event) {
                _vm.popupPermissionPassword = false
              },
            },
          }),
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-1/6 w-full mt-0" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Serviços"),
                ]),
                _c("v-select", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: { required: _vm.transaction.type === "CREDIT" },
                      expression: "{ required: transaction.type === 'CREDIT' }",
                    },
                  ],
                  attrs: {
                    "data-vv-as": "Serviços",
                    "data-vv-scope": "transaction",
                    name: "services",
                    options: _vm.servicePackages,
                    multiple: "",
                    placeholder: "Selecione",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  on: {
                    input: function ($event) {
                      return _vm.recalculateTotal()
                    },
                    "option:deselected": _vm.serviceDeselected,
                    "option:selected": _vm.newServiceSelected,
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [
                          _vm._v(
                            "\n            Nenhum resultado encontrado.\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.transaction.servicePackages,
                    callback: function ($$v) {
                      _vm.$set(_vm.transaction, "servicePackages", $$v)
                    },
                    expression: "transaction.servicePackages",
                  },
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("transaction.services"),
                        expression: "errors.has('transaction.services')",
                      },
                    ],
                    staticClass: "text-danger text-sm",
                  },
                  [_vm._v(_vm._s(_vm.errors.first("transaction.services")))]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/6 w-full mt-0" },
              [
                _c("vs-input", {
                  directives: [
                    {
                      name: "currency",
                      rawName: "v-currency",
                      value: _vm.currencyConfig,
                      expression: "currencyConfig",
                    },
                  ],
                  ref: "subTotal",
                  staticClass: "w-full",
                  attrs: { label: "Valor" },
                  model: {
                    value: _vm.transaction.value_parcel,
                    callback: function ($$v) {
                      _vm.$set(_vm.transaction, "value_parcel", $$v)
                    },
                    expression: "transaction.value_parcel",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/6 w-full mt-0" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Desconto " + _vm._s(_vm.transaction.discount_type)),
                ]),
                _c(
                  "vx-input-group",
                  {
                    attrs: {
                      prependClasses:
                        "border border-solid border-grey border-r-0",
                    },
                  },
                  [
                    _c("template", { slot: "append" }, [
                      _c(
                        "div",
                        { staticClass: "append-text btn-addon" },
                        [
                          _c(
                            "vs-button",
                            { on: { click: _vm.toggleDiscountType } },
                            [_vm._v(_vm._s(_vm.transaction.discount_type))]
                          ),
                        ],
                        1
                      ),
                    ]),
                    _vm.transaction.discount_type === "%"
                      ? _c("vs-input", {
                          key: "1__",
                          staticClass: "w-full",
                          attrs: { type: "number" },
                          model: {
                            value: _vm.transaction.discount,
                            callback: function ($$v) {
                              _vm.$set(_vm.transaction, "discount", $$v)
                            },
                            expression: "transaction.discount",
                          },
                        })
                      : _c("vs-input", {
                          directives: [
                            {
                              name: "currency",
                              rawName: "v-currency",
                              value: _vm.currencyConfig,
                              expression: "currencyConfig",
                            },
                          ],
                          key: "2___",
                          ref: "discount",
                          staticClass: "w-full",
                          model: {
                            value: _vm.transaction.discount,
                            callback: function ($$v) {
                              _vm.$set(_vm.transaction, "discount", $$v)
                            },
                            expression: "transaction.discount",
                          },
                        }),
                  ],
                  2
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/6 w-full mt-0" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Acréscimo " + _vm._s(_vm.transaction.addition_type)),
                ]),
                _c(
                  "vx-input-group",
                  {
                    attrs: {
                      prependClasses:
                        "border border-solid border-grey border-r-0",
                    },
                  },
                  [
                    _c("template", { slot: "append" }, [
                      _c(
                        "div",
                        { staticClass: "append-text btn-addon" },
                        [
                          _c(
                            "vs-button",
                            { on: { click: _vm.toggleAdditionType } },
                            [_vm._v(_vm._s(_vm.transaction.addition_type))]
                          ),
                        ],
                        1
                      ),
                    ]),
                    _vm.transaction.addition_type === "%"
                      ? _c("vs-input", {
                          key: "3__",
                          staticClass: "w-full",
                          attrs: { type: "number" },
                          model: {
                            value: _vm.transaction.addition,
                            callback: function ($$v) {
                              _vm.$set(_vm.transaction, "addition", $$v)
                            },
                            expression: "transaction.addition",
                          },
                        })
                      : _c("vs-input", {
                          directives: [
                            {
                              name: "currency",
                              rawName: "v-currency",
                              value: _vm.currencyConfig,
                              expression: "currencyConfig",
                            },
                          ],
                          key: "4___",
                          ref: "addition",
                          staticClass: "w-full",
                          model: {
                            value: _vm.transaction.addition,
                            callback: function ($$v) {
                              _vm.$set(_vm.transaction, "addition", $$v)
                            },
                            expression: "transaction.addition",
                          },
                        }),
                  ],
                  2
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/6 w-full mt-0" },
              [
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  staticClass: "w-full",
                  attrs: {
                    type: "date",
                    label: "Vencimento",
                    "data-vv-as": "Vencimento",
                    "data-vv-scope": "transaction",
                    name: "expiration_date",
                  },
                  on: {
                    change: function ($event) {
                      return _vm.recalculateTotal()
                    },
                  },
                  model: {
                    value: _vm.transaction.expiration_date,
                    callback: function ($$v) {
                      _vm.$set(_vm.transaction, "expiration_date", $$v)
                    },
                    expression: "transaction.expiration_date",
                  },
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("transaction.expiration_date"),
                        expression: "errors.has('transaction.expiration_date')",
                      },
                    ],
                    staticClass: "text-danger text-sm",
                  },
                  [_vm._v("Este campo é obrigatório.")]
                ),
                !_vm.isSameOrAfterToday(_vm.transaction.expiration_date)
                  ? _c("span", { staticClass: "text-danger text-sm" }, [
                      _vm._v("Movimentação vencida."),
                    ])
                  : _vm._e(),
              ],
              1
            ),
            _c("div", { staticClass: "vx-col md:w-1/6 w-full mt-0" }, [
              _c("label", { staticClass: "vs-input--label" }, [
                _vm._v("Novo Valor"),
              ]),
              _c("h1", { staticClass: "flex flex-wrap justify-center" }, [
                _c("sup", { staticClass: "text-lg" }, [_vm._v("R$")]),
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.total
                        .toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        })
                        .replace("R$", "")
                    )
                  ),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-1/6 w-full mt-2" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Tipo de Pagamento"),
                ]),
                _c("v-select", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  staticClass: "vue_select_drop_size_180",
                  attrs: {
                    reduce: (option) => option.value,
                    clearable: false,
                    "data-vv-as": "Tipo de Pagamento",
                    "data-vv-scope": "transaction",
                    name: "type_payment_id",
                    options: _vm.typePayments,
                    placeholder: "Selecione",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  on: { input: _vm.filterAccounts },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [
                          _vm._v(
                            "\n            Desculpe, nenhum resultado encontrado.\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.transaction.type_payment_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.transaction, "type_payment_id", $$v)
                    },
                    expression: "transaction.type_payment_id",
                  },
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("transaction.type_payment_id"),
                        expression: "errors.has('transaction.type_payment_id')",
                      },
                    ],
                    staticClass: "text-danger text-sm",
                  },
                  [_vm._v("Este campo é obrigatório.")]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/6 w-full mt-2" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Conta"),
                ]),
                _c("v-select", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  staticClass: "vue_select_drop_size_180",
                  attrs: {
                    disabled: !_vm.transaction.type_payment_id,
                    reduce: (option) => option.value,
                    clearable: false,
                    "data-vv-as": "Conta",
                    "data-vv-scope": "transaction",
                    name: "account",
                    options: _vm.accountsFilteredOptions,
                    placeholder: "Selecione",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [
                          _vm._v(
                            "\n            Desculpe, nenhum resultado encontrado.\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.transaction.account_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.transaction, "account_id", $$v)
                    },
                    expression: "transaction.account_id",
                  },
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("transaction.account"),
                        expression: "errors.has('transaction.account')",
                      },
                    ],
                    staticClass: "text-danger text-sm",
                  },
                  [_vm._v("Este campo é obrigatório.")]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/6 w-full mt-2" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Plano de contas"),
                ]),
                _c("v-select", {
                  staticClass: "vue_select_drop_size_180",
                  attrs: {
                    reduce: (option) => option.value,
                    options:
                      _vm.transaction.type === "DEBIT"
                        ? _vm.accountPlansDespesa
                        : _vm.accountPlans,
                    placeholder: "Selecione",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [
                          _vm._v(
                            "\n            Desculpe, nenhum resultado encontrado.\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.transaction.account_plan_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.transaction, "account_plan_id", $$v)
                    },
                    expression: "transaction.account_plan_id",
                  },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "vx-col md:w-1/6 w-full mt-2" }, [
              _c(
                "div",
                { staticClass: "flex flex-wrap items-center justify-end" },
                [
                  _c("label", { staticClass: "vs-input--label mr-2" }, [
                    _vm._v("Aplicar Juros"),
                  ]),
                  _c(
                    "vs-switch",
                    {
                      staticClass: "w-1/2 mt-2",
                      attrs: {
                        "icon-pack": "feather",
                        "vs-icon-on": "icon-check-circle",
                        "vs-icon-off": "icon-slash",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.recalculateTotal()
                        },
                      },
                      model: {
                        value: _vm.transaction.applyInterest,
                        callback: function ($$v) {
                          _vm.$set(_vm.transaction, "applyInterest", $$v)
                        },
                        expression: "transaction.applyInterest",
                      },
                    },
                    [
                      _c("span", { attrs: { slot: "on" }, slot: "on" }, [
                        _vm._v("SIM"),
                      ]),
                      _c("span", { attrs: { slot: "off" }, slot: "off" }, [
                        _vm._v("NÃO"),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "flex flex-wrap items-center justify-end" },
                [
                  _c("label", { staticClass: "vs-input--label mr-2" }, [
                    _vm._v("Situação"),
                  ]),
                  _c(
                    "vs-switch",
                    {
                      staticClass: "w-1/2 mt-2",
                      attrs: {
                        "icon-pack": "feather",
                        "vs-icon-on": "icon-check-circle",
                        "vs-icon-off": "icon-slash",
                      },
                      on: { input: _vm.changeSituation },
                      model: {
                        value: _vm.transaction.situation,
                        callback: function ($$v) {
                          _vm.$set(_vm.transaction, "situation", $$v)
                        },
                        expression: "transaction.situation",
                      },
                    },
                    [
                      _c("span", { attrs: { slot: "on" }, slot: "on" }, [
                        _vm._v("QUITADO"),
                      ]),
                      _c("span", { attrs: { slot: "off" }, slot: "off" }, [
                        _vm._v("ABERTO"),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ]),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/6 w-full mt-2" },
              [
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate.initial",
                      value: { required: _vm.transaction.situation },
                      expression: "{ required: transaction.situation }",
                      modifiers: { initial: true },
                    },
                  ],
                  staticClass: "w-full",
                  attrs: {
                    type: "date",
                    label: "Data Pagamento",
                    disabled: !_vm.transaction.situation,
                    "data-vv-as": "Vencimento",
                    "data-vv-scope": "transaction",
                    name: "payday",
                  },
                  on: {
                    change: function ($event) {
                      _vm.recalculateTotal()
                      _vm.checkPayday(_vm.transaction)
                    },
                  },
                  model: {
                    value: _vm.transaction.payday,
                    callback: function ($$v) {
                      _vm.$set(_vm.transaction, "payday", $$v)
                    },
                    expression: "transaction.payday",
                  },
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("transaction.payday"),
                        expression: "errors.has('transaction.payday')",
                      },
                    ],
                    staticClass: "text-danger text-sm",
                  },
                  [_vm._v("Campo obrigatório.")]
                ),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.transaction.futureDate,
                        expression: "transaction.futureDate",
                      },
                    ],
                    staticClass: "text-danger text-sm",
                  },
                  [_vm._v("Data futura não permitida")]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/6 w-full mt-2" },
              [
                _c("vs-input", {
                  directives: [
                    {
                      name: "currency",
                      rawName: "v-currency",
                      value: _vm.currencyConfig,
                      expression: "currencyConfig",
                    },
                  ],
                  ref: "valuePay",
                  staticClass: "w-full",
                  attrs: {
                    label: "Valor Pago",
                    disabled: !_vm.transaction.situation,
                  },
                  model: {
                    value: _vm.transaction.value_pay,
                    callback: function ($$v) {
                      _vm.$set(_vm.transaction, "value_pay", $$v)
                    },
                    expression: "transaction.value_pay",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col w-full mt-2" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: { label: "Descrição" },
                  model: {
                    value: _vm.transaction.observation,
                    callback: function ($$v) {
                      _vm.$set(_vm.transaction, "observation", $$v)
                    },
                    expression: "transaction.observation",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("vs-divider"),
          _c("div", { staticClass: "vx-row" }, [
            _c("div", { staticClass: "vx-col w-full" }, [
              _c(
                "div",
                { staticClass: "mt-4 flex flex-wrap items-center justify-end" },
                [
                  _c(
                    "vs-button",
                    {
                      staticClass: "ml-auto",
                      attrs: { color: "secondary" },
                      on: { click: _vm.cancel },
                    },
                    [_vm._v("Cancelar")]
                  ),
                  _c(
                    "vs-button",
                    {
                      staticClass: "ml-4",
                      attrs: {
                        disabled:
                          !_vm.validateTransaction ||
                          _vm.disableTransactionSave ||
                          _vm.disableSave,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.save()
                        },
                      },
                    },
                    [_vm._v("Salvar")]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }